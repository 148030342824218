import "./Skills.css"


const SKILLS = [
  [
    { skill: "Python", since: new Date(2017, 8) },
    { skill: "Django", since: new Date(2017, 8) },
    { skill: "DRF", since: new Date(2018, 0) },
    { skill: "Celery", since: new Date(2017, 8) },
  ],
  [
    { skill: "PostgreSQL", since: new Date(2007, 0) },
    { skill: "RabbitMQ", since: new Date(2016, 8) },
    { skill: "Kafka", since: new Date(2019, 8) },
    { skill: "Redis", since: new Date(2018, 1) },
  ],
  [
    { skill: "AWS", since: new Date(2017, 8) },
    { skill: "Docker", since: new Date(2016, 8) },
    { skill: "Terraform", since: new Date(2020, 8) },
    { skill: "GitHub Actions", since: new Date(2021, 8) },
  ],
];

const STYLES = [{ width: "25%" }, { width: "35%" }, {}];

const roundToHalf = (value) => {
  const frac = value - Math.floor(value);
  if (frac < 0.25) {
    return Math.floor(value);
  }
  if (frac < 0.75) {
    return Math.floor(value) + 0.5;
  }
  return Math.ceil(value);
}

const Skill = (props) => {
  const {skill, since} = props;
  const now = new Date();
  const years = (now - since) / (365 * 24 * 60 * 60 * 1000);
  return <li>
    {skill} <span className="skill-exp">({roundToHalf(years)} yrs)</span>
  </li>
}

const Skills = () => {
  return (
    <div className="section row">
      <h2 className="col">Skills</h2>
      <div className="section-text col-right row">
        {SKILLS.map((e, i) => (
          <ul key={i} className="skills" style={STYLES[i]}>
            {e.map((skill, i) => (
              <Skill key={i} skill={skill.skill} since={skill.since} />
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default Skills;
