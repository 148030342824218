const About = () => {
  return (
    <div className="section row">
      <h2 className="col">About me</h2>
      <div className="section-text col-right">
        Experienced Full Stack Developer specializing in backend development and
        system architecture, with expertise in Python, PostgreSQL, Docker, and
        AWS. Skilled in designing scalable business applications and automating
        cloud infrastructure. Proven ability to deliver high-quality solutions
        in agile environments.
      </div>
    </div>
  );
};

export default About;
